import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import LoginResponse from './login-interface';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  login(email: string, password: string) {
    return this.http
      .post<LoginResponse>(
        `${environment.apiUrl}/sw-gateway/ryke-authenticate-api/rest/api/authenticate/login`,
        { email, password },
      )
      .pipe(tap(this.saveTokens));
  }

  refreshToken() {
    const token = localStorage.getItem('refreshToken');

    return this.http
      .post<LoginResponse>(
        `${environment.apiUrl}/sw-gateway/ryke-authenticate-api/rest/api/authenticate/refreshToken`,
        { token },
      )
      .pipe(
        tap(this.saveTokens),
        catchError((err) => {
          this.logout();
          return throwError(() => err);
        }),
      );
  }

  logout() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.router.navigate(['login']);
  }

  private saveTokens(response: LoginResponse) {
    const { accessToken, refreshToken } = response.result;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  }
}
